import _ from 'lodash';
import { MetricsPanelCtrl } from 'app/plugins/sdk';
import defaultsDeep from 'lodash/defaultsDeep';
import { TemplateSrv } from 'app/features/templating/template_srv';
import { PanelEvents, FieldConfigProperty, DataQuery, PanelPlugin } from '@grafana/data';
import { auto, ISCEService } from 'angular';
import { AnnotationsSrv } from 'app/features/annotations/all';
import { PanelModel } from 'app/features/dashboard/state';
import { PanelQueryRunner } from '../../../features/query/state/PanelQueryRunner';
import { piqPanelEditor } from './editor';
import * as plotly from '../../../../piq/plotly.min.js';
import * as math from '../../../../piq/math.min.js';
//import * as xlsx from './lib/xlsx.min.js';
import * as jszip from '../../../../piq/jszip.min.js';
import * as moment from '../../../../piq/moment.min.js';
import * as Sortable from '../../../../piq/Sortable.min.js';

interface KeyValue {
  key: string;
  value: any;
}

const defaultContent = `
<script type="text/javascript" src="./public/piq/piqlib.js"></script>
<script type="text/javascript" >
  var calcServerURL='https://iiot-calc.processiq.com.au'
  var isRemote = false
</script>
`;

export class CalcCtrl extends MetricsPanelCtrl {
  static templateUrl = 'module.html';
  content: string;
  dataRaw: any;

  annotationsPromise: any;
  panelDefaults = {
    mode: 'html', // 'html', 'markdown', 'text'
    content: defaultContent,
    mathjs: {},
    xlsx: {},
    plotly: {},
    jszip: {},
    moment: {},
    Sortable: {},
  };

  // Simple example showing the last value of all data
  firstValues: KeyValue[] = [];

  /** @ngInject */
  constructor(
    $scope: any,
    $injector: auto.IInjectorService,
    templateSrv: TemplateSrv,
    private $sce: ISCEService,
    private annotationsSrv: AnnotationsSrv
  ) {
    super($scope, $injector);
    defaultsDeep(this.panel, this.panelDefaults);

    // Get results directly as DataFrames
    (this as any).dataFormat = 'series';
    this.useDataFrames = true;
    this.annotationsPromise = Promise.resolve({ annotations: [] });

    this.events.on(PanelEvents.render, this.onRender.bind(this));
    this.events.on(PanelEvents.dataSnapshotLoad, this.onDataReceived.bind(this));
    this.events.on(PanelEvents.editModeInitialized, this.onInitEditMode.bind(this));
    this.events.on(PanelEvents.dataError, this.onDataError.bind(this));

    this.panel.mathjs = { math };
    //this.panel.xlsx = { xlsx };
    this.panel.plotly = { plotly };
    this.panel.jszip = { jszip };
    this.panel.moment = { moment };
    this.panel.Sortable = { Sortable };
    this.panel.PanelEvents = PanelEvents;
    this.panel.insertQuery = this.insertQuery.bind(this);
    this.panel.updateQuery = this.updateQuery.bind(this);
    this.panel.executeQuery = this.issueQuery.bind(this);
    this.panel.executeQueries = this.issueQueries.bind(this);
    this.panel.emitEvent = this.emitEvent.bind(this);
    this.panel.getThis = this.getThis.bind(this);
    this.panel.setLoading = this.setLoading.bind(this);
    this.panel.emitter = { events: this.getEventEmitter.bind(this) };

    const renderWhenChanged = (scope: any) => {
      const { panel } = scope.ctrl;
      return [panel.content, panel.mode].join();
    };

    $scope.$watch(
      renderWhenChanged,
      _.throttle(() => {
        this.render();
      }, 1000)
    );
  }

  onInitEditMode() {
    this.addEditorTab('Options', piqPanelEditor, 2);
  }

  onRender() {
    this.updateContent(this.panel.content);

    // Tells the screen capture system that you finished
    this.renderingCompleted();
  }

  onDataReceived(dataList: any) {
    this.dataRaw = dataList;
  }

  onDataError(err: any) {
    this.dataRaw = [];
    console.log('onDataError', err);
  }

  updateContent(html: string) {
    try {
      this.content = this.$sce.trustAsHtml(this.templateSrv.replace(html, this.panel.scopedVars));
    } catch (e) {
      console.log('updateContent error: ', e);
      this.content = this.$sce.trustAsHtml(html);
    }
  }

  updateQuery(query: DataQuery, index: number) {
    if (this.panel.targets[index] !== undefined) {
      this.panel.changeQuery(query, index);
      return this.panel.targets[index].refId;
    }
    console.log('updateQuery: Target index not found');
  }

  insertQuery(query: DataQuery) {
    const l1 = this.panel.targets.length;
    this.panel.addQuery(query);
    const l2 = this.panel.targets.length;
    if (l1 !== l2) {
      return this.panel.targets[this.panel.targets.length - 1].refId;
    }
    console.log('insertQuery: Target not added');
  }

  issueQueries(datasource: any) {
    this.annotationsPromise = this.annotationsSrv.getAnnotations({
      dashboard: this.dashboard,
      panel: this.panel,
      range: this.range,
    });

    return this.annotationsSrv.datasourcePromises.then((r: any) => {
      return super.issueQueries(datasource);
    });
  }

  issueQuery(index: number) {
    if (this.panel.targets[index] !== undefined) {
      const targets = [this.panel.targets[index]];

      const panel = this.panel as PanelModel;
      const queryRunner = this.panel.getQueryRunner() as PanelQueryRunner;

      if (!this.querySubscription) {
        this.querySubscription = queryRunner
          .getData({ withTransforms: true, withFieldConfig: true })
          .subscribe(this.panelDataObserver);
      }

      return queryRunner.run({
        datasource: panel.datasource,
        queries: targets,
        panelId: panel.id,
        dashboardId: this.dashboard.id,
        timezone: this.dashboard.timezone,
        timeRange: this.range,
        maxDataPoints: panel.maxDataPoints || 0,
        minInterval: panel.interval,
        scopedVars: panel.scopedVars,
        cacheTimeout: panel.cacheTimeout,
      });
    } else {
      console.log('issueQuery: Target index not found');
      return false;
    }
  }

  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  getThis() {
    return this;
  }

  getEventEmitter() {
    return this.events;
  }

  emitEvent(event: string) {
    this.events.emit(event);
  }
}

// Use new react style configuration
export const plugin = new PanelPlugin<any, any>(null).useFieldConfig({
  disableStandardOptions: [
    FieldConfigProperty.NoValue,
    FieldConfigProperty.Thresholds,
    FieldConfigProperty.Max,
    FieldConfigProperty.Min,
    FieldConfigProperty.Decimals,
    FieldConfigProperty.Color,
    FieldConfigProperty.Mappings,
  ],
});

// Use the angular ctrt rather than a react one
plugin.angularPanelCtrl = CalcCtrl;

// export { CalcCtrl as PanelCtrl };

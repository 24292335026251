import _ from 'lodash';
import { IQService } from 'angular';

export class PIQPanelEditorCtrl {
  panel: any;
  panelCtrl: any;

  /** @ngInject */
  constructor($scope: any) {
    $scope.editor = this;
    this.panelCtrl = $scope.ctrl;
    this.panel = this.panelCtrl.panel;
  }

  render() {
    this.panelCtrl.render();
  }
}

/** @ngInject */
export function piqLambdaEditor($q: IQService, uiSegmentSrv: any) {
  'use strict';
  return {
    restrict: 'E',
    scope: true,
    templateUrl: 'public/app/plugins/panel/piq-angular-text/editor.html',
    controller: PIQPanelEditorCtrl,
  };
}
